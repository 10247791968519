import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup} from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() field: any = {};
  @Input() group: FormGroup;
  @Output() controlEventEmitter = new EventEmitter<any>();
  @Output() controlConfirmEmitter = new EventEmitter<any>();
  _ = _;

  constructor() {
  }

  ngOnInit() {
  }

  onEvent(event, type) {
    if (this.field.hasOwnProperty('events') && _.indexOf(this.field.events, type) > -1) {
      this.controlEventEmitter.emit({
        field: this.field.name,
        eventType: type,
        event: event
      });
    }
  }
}
