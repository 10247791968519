import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {map, takeUntil} from "rxjs/operators";
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  private isLogged: boolean;
  private destroy$: Subject<void> = new Subject();

  constructor(
    oidcSecurityService: OidcSecurityService
  ) {
    let self = this;
    oidcSecurityService.getIsAuthorized().pipe(
        map(user => !!user),
        takeUntil(this.destroy$)
      ).subscribe( isLoggedIn => {
        self.isLogged = isLoggedIn;
      });
  }

  async ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
