import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatMenuTrigger} from '@angular/material';
import {fadeInAnimation} from '../../../../animations';
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
  animations: [
    fadeInAnimation
  ]
})

export class UserHeaderComponent implements OnInit {
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

  search: string;
  user$ = null;
  notifications = {
    section: 1,
    read: [],
    unread: []
  };
  active: boolean = false;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    public router: Router,
    private activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.search = this.activeRoute.snapshot.queryParamMap.get('search');
    this.user$ = this.oidcSecurityService.getUserData();
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

  submitSearch(search) {
    this.search = search;
    this.router.navigate([], {
      queryParams: {search: this.search}
    });
  }

  clearSearch() {
    this.search = null;
    this.router.navigate([], {
      queryParams: {search: this.search}
    });
  }

  closeMenu() {
    this.trigger.forEach(item => {
      item.closeMenu();
    });
  }
}
