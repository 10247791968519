import {Component, OnInit} from '@angular/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as _ from "lodash";
import * as html2PdfMake from 'html-to-pdfmake';
import i18next from "i18next";
import {fadeInOutAnimationV2, fadeInAnimation} from "../../../animations";
import {HttpClient} from "@angular/common/http";
import {Params} from "@angular/router";
import {catchError} from "rxjs/operators";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-amazon-product-info',
  templateUrl: './amazon-product-info.component.html',
  styleUrls: ['./amazon-product-info.component.scss'],
  animations: [
    fadeInOutAnimationV2,
    fadeInAnimation
  ],
  host: {'[@fadeInAnimation]': ''}
})
export class AmazonProductInfoComponent implements OnInit {
  asin: string = "";
  invalidAsin: boolean = false;
  productPdf = null;
  pdfStyles = {
    header: {
      fontSize: 18,
      bold: true
    },
    subHeader: {
      fontSize: 14,
      bold: true
    },
    fontSizeNormal: {
      fontSize: 12
    },
    smallItalic: {
      fontSize: 10,
      italics: true,
      alignment: 'left'
    },
    alignCenter: {
      alignment: 'center'
    },
    alignLeft: {
      alignment: 'left'
    }
  };
  pdfBase64: string = null;
  isLoading: boolean = false;

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {
  }

  pdfGenerateSubmit() {
    if (!this.asin) {
      return false;
    }

    this.invalidAsin = false;
    this.productPdf = null;
    this.pdfBase64 = null;

    const url = 'products/get-all-amazon-info';
    let self = this;
    this.isLoading = true;
    this.httpClient.post(url, {ASIN: this.asin}).pipe(
      catchError(e => {
        self.invalidAsin = true;
        this.isLoading = false;
        console.log(e);
        return e;
      })
    ).subscribe((response: Params) => {
      self.generatePDF(response.body).then(dataUrl => {
        self.pdfBase64 = dataUrl;
        self.isLoading = false;
      }).catch(error => {
        console.log(error);
        self.isLoading = false;
      });
    });
  }

  generatePDF(productInfo): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let {
        title,
        sellerName,
        rating,
        reviewsCount,
        price,
        images,
        featureBullets,
        productDescriptionHtml,
        details
      } = productInfo;
      let content: Array<any> = [
        {text: title, style: ['header', 'alignCenter'], margin: [0, 0, 0, 5]},
        {text: i18next.t('bySeller', {sellerName}), style: 'smallItalic', margin: [0, 0, 0, 5]},
        {text: rating, style: 'smallItalic', margin: [0, 0, 0, 5]},
        {text: reviewsCount, style: 'smallItalic', margin: [0, 0, 0, 5]}
      ];

      _.mapKeys(price, (value, key) => {
        let textObj = {
          text: key + ' ' + value,
          style: 'fontSizeNormal',
          margin: [0, 0, 0, 5]
        };

        content.push(textObj);
      });

      for (const imagesArr of images) {
        let columnsObj = {columns: _.fill(Array(3), null), columnGap: 30, margin: [0, 10], style: 'alignCenter'};

        for (let i = 0; i < imagesArr.length; i++) {
          const imageUrl = imagesArr[i];

          let base64Image = await this.getBase64Image(imageUrl);
          columnsObj.columns[i] = {
            image: base64Image,
            fit: [150, 150]
          };
        }

        content.push(columnsObj);
      }

      if (featureBullets && featureBullets.length) {
        content.push({ul: featureBullets, margin: [0, 20]});
      }

      if (productDescriptionHtml) {
        let htmlToPdfMakeObj = html2PdfMake(productDescriptionHtml);
        content.push({text: i18next.t('Description'), style: 'subHeader', margin: [0, 10]});
        content.push(htmlToPdfMakeObj);
      }

      if (details) {
        let {technicalDetails, additionalInfo} = details;

        let layout = {
          hLineColor: (i, node) => {
            return '#ccc';
          }
        };

        if (!_.isEmpty(technicalDetails)) {
          content.push({text: i18next.t('TechnicalDetails'), style: 'subHeader', margin: [0, 10]});
          let table = this.renderTable(technicalDetails);
          content.push({table, margin: [0, 0, 0, 10], layout});
        }

        if (!_.isEmpty(additionalInfo)) {
          content.push({text: i18next.t('AdditionalInfo'), style: 'subHeader', margin: [0, 10]});
          let table = this.renderTable(additionalInfo);
          content.push({table, layout});
        }
      }

      const docDefinition = {
        info: {
          title
        },
        content,
        styles: this.pdfStyles
      };
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);

      pdfDocGenerator.getDataUrl((dataUrl) => {
        resolve(dataUrl);
      }, error => reject(error));
    });
  }

  renderTable(tableObj) {
    let table = {
      body: [],
      widths: [150, '*'],
    };

    _.mapKeys(tableObj, (value, key) => {
      let firstCol = {
        text: key,
        fillColor: '#F5F5F5',
        border: [false, true, false, true]
      };

      let secondCol = {
        text: value,
        border: [false, true, false, true]
      };

      table.body.push([firstCol, secondCol]);
    });

    return table;
  }

  getBase64Image(imgUrl): Promise<any> {
    return new Promise((resolve, reject) => {
      let img = new Image();
      // onload fires when the image is fully loaded, and has width and height

      img.crossOrigin = 'anonymous';
      img.onload = () => {

        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL("image/png");

        resolve(dataURL); // the base64 string
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = imgUrl;
    });
  }
}
