import { Component, OnInit, ViewChildren, QueryList } from "@angular/core";
import i18next from "i18next";
import { Router } from "@angular/router";
import { fadeInAnimation } from "../../../animations/fade-animation";
import { MatMenuTrigger } from "@angular/material";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {take} from "rxjs/operators";

@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" }
})
export class UserSettingsComponent implements OnInit {
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

  user$ = null;
  notificationPeriod: string = "";
  marketingMailsSubscription: string = "";
  enabledSurveys: boolean = false;
  fields = {
    changePasswordFields: [
      {
        type: "control",
        control: "password",
        name: "oldPassword",
        label: "OldPassword",
        value: "",
        validator: ["required"]
      },
      {
        type: "control",
        control: "password",
        name: "newPassword",
        label: "NewPassword",
        value: "",
        validator: ["required", "password"],
        passwordRequirementShow: true
      },
      {
        type: "control",
        control: "password",
        name: "confirmNewPassword",
        label: "ConfirmNewPassword",
        value: "",
        validator: ["required", "password", "confirm:newPassword"]
      }
    ],
    urlFields: [
      {
        type: "control",
        control: "input",
        name: "urlToStore",
        label: "UrlToStore",
        value: "",
        validator: ["url"]
      }
    ]
  };
  formGlobals: any = {};
  formSettings = {
    submitText: i18next.t("Save")
  };
  resetForm: boolean = false;
  constructor(
    public oidcSecurityService: OidcSecurityService,
    public router: Router
  ) {}

  async ngOnInit() {
    this.user$ = this.oidcSecurityService.getUserData();

    this.user$.pipe(
      take(1)
    ).subscribe(
      user => {
        this.notificationPeriod = user.hasOwnProperty("notification_period")
          ? user.notification_period
          : "never";
        this.marketingMailsSubscription = user.hasOwnProperty(
          "marketing_mails"
        )
          ? user.marketing_mails
          : "off";
      }
    );
  }

  closeMenu() {
    this.trigger.forEach(item => {
      item.closeMenu();
    });
  }

  changeNotificationPeriod(event) {
    // let value = event.currentTarget.dataset.value;
    // let url = "users/change-notification-settings";
    // if (value) {
    //   this.httpService.post(
    //     url,
    //     {period: value},
    //     response => {
    //       this.authService.setUserData(JSON.stringify(response.body));
    //       this.notificationPeriod = value;
    //     },
    //     error => {
    //       return false;
    //     }
    //   );
    // }
  }

  subscribeMarketingMails(value) {
    // let url = "users/marketing-mails-subscribe";
    // if (value) {
    //   this.httpService.post(
    //     url,
    //     {status: value},
    //     response => {
    //       this.authService.setUserData(JSON.stringify(response.body));
    //       this.marketingMailsSubscription = value;
    //     },
    //     error => {
    //       return false;
    //     }
    //   );
    // }
  }

  enableSurveys(value) {
    // TODO
    this.enabledSurveys = value;
  }
}
