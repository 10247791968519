import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {fadeInAnimation} from '../../../animations/fade-animation';
import * as _ from 'lodash';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  animations: [
    fadeInAnimation,
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', visibility: 'hidden'})),
      state('expanded', style({height: '100px', visibility: 'visible'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  host: {'[@fadeInAnimation]': ''}
})
export class InvoicesComponent implements OnInit {

  dataSource = new MatTableDataSource(ELEMENT_DATA);
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  columnsToDisplay = ['name', 'weight', 'symbol', 'position'];
  expandedElement: null;

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.dataSource.sort = this.sort;
  }

  openRow(element) {
    if (this.expandedElement !== element) {
      console.log(element);
      this.expandedElement = element;
    }
  }

}

const ELEMENT_DATA = [
  {
    position: 7,
    name: 'Nitrogen',
    weight: 14.0067,
    symbol: 'N'
  }, {
    position: 8,
    name: 'Oxygen',
    weight: 15.9994,
    symbol: 'O'
  }, {
    position: 9,
    name: 'Fluorine',
    weight: 18.9984,
    symbol: 'F'
  }, {
    position: 10,
    name: 'Neon',
    weight: 20.1797,
    symbol: 'Ne'
  }
];
