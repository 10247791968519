import { Component, OnInit } from '@angular/core';
import {take, tap} from "rxjs/operators";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {Router} from "@angular/router";
import {UserDataService} from "../../../services/auth/UserDataService";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-new-user-guide',
  templateUrl: './new-user-guide.component.html',
  styleUrls: ['./new-user-guide.component.scss']
})
export class NewUserGuideComponent implements OnInit {
  public imageSources: Array<string> = [];
  constructor(private oidcSecurityService: OidcSecurityService,
              private userDataService: UserDataService,
              private router: Router) {
    this.imageSources = [
      'https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_960_720.jpg',
      'https://cdn.pixabay.com/photo/2016/10/21/14/50/plouzane-1758197_960_720.jpg',
      'https://cdn.pixabay.com/photo/2016/10/20/18/35/sunrise-1756274_960_720.jpg'
    ]
  }

  ngOnInit() {
    this.oidcSecurityService.getUserData()
      .pipe(take(1))
      .subscribe(user => {
        let guideVisitedKey = environment.oidc_configs.openIdConfiguration.client_id + '_guide_visited';

        if (!user.userData || !user.userData.hasOwnProperty(guideVisitedKey)) {
          return this.userDataService.setUserData(guideVisitedKey, true).pipe(
            take(1),
          ).subscribe();
        }
      });
  }
}
