import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import i18next from "i18next";
import { DialogComponent } from "../../modals/dialog/dialog.component";
import { DomSanitizer } from "@angular/platform-browser";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {catchError, take} from "rxjs/operators";
import {Params} from "@angular/router";
import {HttpClient} from "@angular/common/http";
const invoiceCorrectionFields = require("./correctionFields.json");

@Component({
  selector: "app-pdf-preview",
  templateUrl: "./pdf-preview.component.html",
  styleUrls: ["./pdf-preview.component.scss"]
})
export class PdfPreviewComponent implements OnInit {
  notIncludedFields;
  successConfirmText;
  errorConfirmText;
  i18next = i18next;
  invoiceCorrectionFields = invoiceCorrectionFields;
  selectedSection: number = 1;
  status: number = 5;
  loadSpinner: boolean = false;
  selectedFields: [];
  reportChecked: boolean = false;
  invoiceModifyForm: FormGroup;
  reportSellerForm: FormGroup;
  subForms: any = {};
  errors: any = {};

  constructor(
    private sanitizer: DomSanitizer,
    public httpClient: HttpClient,
    public oidcSecurityService: OidcSecurityService,
    private fb: FormBuilder,
    public confirmDialog: MatDialog,
    public dialogRef: MatDialogRef<PdfPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.notIncludedFields = ["DataTransmissionConfirmation"];
    this.buildForms();
  }

  close(action) {
    this.dialogRef.close(action);
    this.clearForms();
  }

  buildForms() {
    this.invoiceModifyForm = this.fb.group({});
    this.invoiceCorrectionFields.forEach(field => {
      this.invoiceModifyForm.addControl(
        field.value,
        this.fb.control("", field.required ? Validators.requiredTrue : null)
      );

      if (field.hasOwnProperty("subFields")) {
        this.subForms[field.value] = this.fb.group({});
        field.subFields.forEach(subField => {
          this.subForms[field.value].addControl(
            subField.value,
            this.fb.control(
              "",
              subField.required ? Validators.requiredTrue : null
            )
          );
        });
      }
    });

    this.reportSellerForm = this.fb.group({
      value: this.fb.control("", Validators.required)
    });
  }

  clearForms() {
    this.invoiceModifyForm.reset();
    this.reportSellerForm.reset();
    Object.keys(this.subForms).forEach(key => {
      this.subForms[key].reset();
    });
  }

  parseForms() {
    let body = {};
    let form = this.invoiceModifyForm;

    Object.keys(form.value).forEach(key => {
      if (form.value[key]) {
        body[key] = {
          value: form.value[key]
        };

        if (this.subForms[key]) {
          body[key].subFields = this.subForms[key].value;
          this.filterFormFields(body[key].subFields);
        }
      }
    });

    return body;
  }

  filterFormFields(formFields) {
    this.notIncludedFields.forEach(field => {
      if (formFields.hasOwnProperty(field)) {
        delete formFields[field];
      }
    });
  }

  changeSection(index) {
    this.selectedSection = 0;
    this.loadSpinner = true;

    setTimeout(() => {
      this.loadSpinner = false;
      this.selectedSection = index;
    }, 1000);
  }

  submitForm(form) {
    this.errors = {};
    this.status = null;

    Object.keys(form.value).forEach(prop => {
      if (form.value[prop] && this.subForms[prop]) {
        this.checkSubFormValidity(prop);
      }
    });

    if (form.valid && !Object.keys(this.errors).length) {
      this.reportChecked && this.selectedSection !== 3
        ? this.changeSection(3)
        : this.confirmModifyInvoice();
    }
  }

  checkSubFormValidity(prop) {
    let subForm = this.subForms[prop];
    Object.keys(subForm.controls).forEach(control => {
      if (
        subForm.controls[control].invalid &&
        subForm.controls[control].errors.required
      ) {
        this.errors[control] = i18next.t("ThisFieldRequired");
      }
    });
  }

  checkIfSelected(prop, form) {
    return form.value[prop];
  }

  checkIfOneIsChecked(form) {
    let isChecked = false;
    Object.keys(form.value).forEach(prop => {
      if (form.value[prop]) {
        isChecked = true;
      }
    });

    return isChecked;
  }

  reportSellerEvent(action) {
    if (action) {
      this.clearForms();
    }

    this.reportChecked = action;
  }

  sendInvoiceModifyToSeller() {
    let body = this.parseForms();
    let invoiceId = this.data.invoice.id;
    this.changeSection(-1);

    this.httpClient.post(`invoices/${invoiceId}/send-modify-email`,
      { body, lang: i18next.language }).pipe(
        catchError(err => this.errorConfirmText = err.error)
      ).subscribe((response: Params) => this.successConfirmText = response.message);
  }

  sendReportSeller() {
    let message = this.reportSellerForm.value.value;
    let sellerId = this.data.invoice.seller.id;
    this.oidcSecurityService.getUserData().pipe(
      take(1)
    ).subscribe(
      user => {
        this.httpClient.post(
          `sellers/${sellerId}/report-seller`,
          {user, message, lang: i18next.language}).pipe(
          catchError((err) => {
            this.errorConfirmText = err.error;
            this.changeSection(-1);
            return err;
          })
        ).subscribe((response: Params) => {
          this.successConfirmText = response.message;
          this.changeSection(-1);
        });
      });
  }

  confirmModifyInvoice() {
    let data = {
      title: "",
      text: null
    };

    switch (this.selectedSection) {
      case 3:
        data.title = i18next.t("ReportSeller");
        let reportText = this.reportSellerForm.value.value;
        let html = i18next.t("ReportSellerConfirmation", {
          reportText,
          interpolation: { escapeValue: false }
        });
        data.text = this.sanitizer.bypassSecurityTrustHtml(html);
        break;
      default:
        data.title = i18next.t("InvoiceCorrection");
        data.text = i18next.t("InvoiceCorrectionConfirmation");
        break;
    }

    const confirmDialogRef = this.confirmDialog.open(DialogComponent, {
      width: "400px",
      data
    });

    confirmDialogRef.afterClosed().subscribe(action => {
      if (action) {
        switch (this.selectedSection) {
          case 2:
            this.sendInvoiceModifyToSeller();
            break;
          case 3:
            this.sendReportSeller();
            break;
          default:
            break;
        }
      }
    });
  }
}
