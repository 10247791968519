import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import {StripeService, StripeCardComponent, ElementOptions, ElementsOptions, Elements, Element} from "ngx-stripe";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {catchError, take} from "rxjs/operators";

@Component({
  selector: 'app-test-stripe',
  templateUrl: './test-stripe.component.html',
  styleUrls: ['./test-stripe.component.scss']
})
export class TestStripeComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  iban: Element;

  stripeTest: FormGroup;
  stripe;

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private httpClient: HttpClient
  ) {
    this.stripe = this.stripeService.stripe;
  }

  ngOnInit() {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });

    this.prepareSepaForm();
  }

  prepareSepaForm() {
    let elements = this.stripe.elements();
    let style = {
      base: {
        color: "#32325d",
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        },
        ":-webkit-autofill": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
        ":-webkit-autofill": {
          color: "#fa755a"
        }
      }
    };

    let options = {
      style,
      supportedCountries: ["SEPA"],
      // If you know the country of the customer, you can optionally pass it to
      // the Element as placeholderCountry. The example IBAN that is being used
      // as placeholder reflects the IBAN format of that country.
      placeholderCountry: "DE"
    };

    this.iban = elements.create("iban", options);
    this.iban.mount("#iban-element");
    this.iban.on("change", (event) => {
      // Handle real-time validation errors from the iban Element.
      if (event.error) {
        console.log('Error!!! :' + event.error.message);
        this.showError(event.error.message);
      }
    });
  }

  showError(errorMsgText) {
    this.changeLoadingState(false);
    let errorMsg = document.querySelector("#error-message");
    errorMsg.textContent = errorMsgText;
    setTimeout(() => {
      errorMsg.textContent = "";
    }, 4000);
  }

  changeLoadingState(isLoading) {
    if (isLoading) {
      document.querySelector("button").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector("button").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  }

  orderComplete(clientSecret) {
    this.stripe.retrievePaymentIntent(clientSecret).then((result) => {
      let paymentIntent = result.paymentIntent;
      let paymentIntentJson = JSON.stringify(paymentIntent, null, 2);

      document.querySelector(".sr-payment-form").classList.add("hidden");
      document.querySelector("pre").textContent = paymentIntentJson;

      document.querySelector(".sr-result").classList.remove("hidden");
      setTimeout(() => {
        document.querySelector(".sr-result").classList.add("expand");
      }, 200);

      this.changeLoadingState(false);
    });
  }

  pay(stripe, iban, clientSecret) {
    this.changeLoadingState(true);
    stripe.confirmPaymentIntent(clientSecret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name: 'Barish',
          email: 'barash@dsi.bg'
        }
      }
    }).then((result) => {
      if (result.error) {
        // Show error to your customer
        this.showError(result.error.message);
      } else {
        this.orderComplete(result.paymentIntent.client_secret);
      }
    });
  }

  buy() {
    const name = this.stripeTest.get('name').value;
    this.stripeService
      .createToken(this.card.getCard(), { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          console.log(result.token);
          this.httpClient.post(environment.payment_service.api_host + '/rest/v1/subscriptions/charge/stripe/card', {
            planSubscriptionId: 9,
            token: result.token.id
          }).pipe(
            catchError(e => { console.log(e); return e; })
          ).subscribe( charge => console.log(charge));
        } else if (result.error) {
          // Error creating the token
          console.log(result.error);
        }
      });
  }
}


// let orderData = {
//   items: [{ id: "photo" }]
// };
//
// // Disable the button until we have Stripe set up on the page
// document.querySelector("button").disabled = true;
//
// // Set up Stripe.js and Elements to use in checkout form
//
// /*
//  * Calls stripe.confirmSepaDebitPayment to generate the mandate and initaite the debit.
//  */
// var pay = function(stripe, iban, clientSecret) {
//   changeLoadingState(true);
//
//   // Initiate the payment.
//   stripe
//     .confirmSepaDebitPayment(clientSecret, {
//       payment_method: {
//         sepa_debit: iban,
//         billing_details: {
//           name: document.querySelector('input[name="name"]').value,
//           email: document.querySelector('input[name="email"]').value
//         }
//       }
//     })
//     .then(function(result) {
//       if (result.error) {
//         // Show error to your customer
//         showError(result.error.message);
//       } else {
//         orderComplete(result.paymentIntent.client_secret);
//       }
//     });
// };
