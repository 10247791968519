import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  user$: Observable<any>;

  constructor(private oidcSecurityService: OidcSecurityService) { }

  ngOnInit() {
    this.user$ = this.oidcSecurityService.getUserData();
  }

}
