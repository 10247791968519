import {trigger, state, animate, transition, style} from '@angular/animations';

export const fadeInOutAnimation =
  trigger('fadeInOutAnimation', [
    state('init', style({opacity: 1})),
    state('true', style({opacity: 1})),
    state('false', style({opacity: 0})),
    transition('init <=> false', animate('0.1s')),
    transition('false <=> init', animate('0.1s')),
    transition('true <=> false', animate('0.1s')),
    transition('false <=> true', animate('0.3s')),
  ]);

export const fadeInOutAnimationV2 =
  trigger('fadeInOutAnimationV2', [
    transition(':enter', [
      style({opacity: 0}),
      animate('0.5s', style({opacity: 1}))
    ]),
    transition(':leave', [
      style({opacity: 1}),
      animate('0.5s', style({opacity: 0}))
    ]),
  ]);
