import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {take, tap} from "rxjs/operators";

@Injectable()
export class AuthCheckService implements CanActivate {

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.oidcSecurityService.getIsAuthorized().
    pipe(
      take(1),
      tap(isLogged => {
        if (!isLogged) {
          if (state.url === '/sign-in') {
            this.oidcSecurityService.authorize();
          }

          this.router.navigate(['/']);
          isLogged = false;
          return isLogged;
        }

        if (state.url === '/logout') {
          return this.oidcSecurityService.logoff();
        }

        return true;
      })
    )
      .toPromise();
  }
}
