export const environment = {
  appTitle: 'Amazonomat',
  production: false,
  api_host_url: 'https://staging.amazonomat.de/',
  appDomain: 'staging.amazonomat.de',
  baseUrl: 'https://staging.amazonomat.de/',
  api_rest_prefix: 'rest/v1/',
  api_key: 'WJGkraINYwCSu9Abga4VzGFBfr9jRhSB',
  appName: 'amazonomat',
  firebaseBaseApi: 'https://us-central1-invoicecloud-auth.cloudfunctions.net/app/api',
  oidc_configs: {
    openIdConfiguration: {
      stsServer: 'https://us-central1-invoicecloud-auth.cloudfunctions.net',
      redirect_url: 'https://staging.amazonomat.de',
      client_id: 'amazonomat',
      response_type: 'code', // 'id_token token' Implicit Flow
      scope: 'openid',
      post_logout_redirect_uri: 'https://staging.amazonomat.de',
      start_checksession: false,
      silent_renew: true,
      silent_renew_url: 'https://staging.amazonomat.de/silent-renew.html',
      silent_renew_offset_in_seconds: 5,

      post_login_route: '/orders',

      forbidden_route: '/',
      unauthorized_route: '/',
      log_console_warning_active: true,
      log_console_debug_active: true,
      max_id_token_iat_offset_allowed_in_seconds: 10,
      disable_iat_offset_validation: true,
      storage: localStorage,
      use_refresh_token: false,
      isauthorizedrace_timeout_in_seconds: 10,
    },

    authWellKnownEndpoints: {
      storage: localStorage,
      issuer: 'https://us-central1-invoicecloud-auth.cloudfunctions.net/app/api/auth',
      jwks_uri: 'https://us-central1-invoicecloud-auth.cloudfunctions.net/app/api/auth/protocol/openid-connect/jwks',
      authorization_endpoint: 'https://invoicecloud-auth.web.app/auth/login',
      token_endpoint: 'https://us-central1-invoicecloud-auth.cloudfunctions.net/app/api/auth/protocol/openid-connect/token',
      userinfo_endpoint: 'https://us-central1-invoicecloud-auth.cloudfunctions.net/app/api/auth/protocol/openid-connect/userInfo',
      end_session_endpoint: 'https://us-central1-invoicecloud-auth.cloudfunctions.net/app/api/auth/protocol/openid-connect/logout',
      use_refresh_token: false
      // check_session_iframe: 'https://us-central1-invoicecloud-auth.cloudfunctions.net/app/api/auth/protocol/openid-connect/checksession',
      // revocation_endpoint: 'https://us-central1-invoicecloud-auth.cloudfunctions.net/app/api/auth/protocol/openid-connect/revocation',
      // introspection_endpoint: 'https://us-central1-invoicecloud-auth.cloudfunctions.net/app/api/auth/protocol/openid-connect/introspect',
    }
  },
  services_config: {
    api_host: 'https://staging.payments.thinkinvoice.com/',
    api_rest_prefix: 'rest/v1/'
  },
  payment_service: {
    api_host: 'https://staging.payments.thinkinvoice.com/',
    api_rest_prefix: 'rest/v1/',
    serviceId: 2,
    stripe: {
      publishable_key: 'pk_test_DIEI7i8mvb0ffe8efprgRfC900CLMfmGoJ'
    },
    paypal: {
      client_id: 'AdYBDRDuF9KHw4oq39OdgDkUOanlp5PEywbm-VrQFOeBSxA12Gyz9lKnivPQUo3FkB0FvJ2ugKhKyZIE'
    }
  }
};
