import { Component, OnInit } from "@angular/core";
import i18next from "i18next";
import { fadeInAnimation } from "../../../animations/fade-animation";
import {Params, Router} from "@angular/router";
import {OidcConfigService, OidcSecurityService} from "angular-auth-oidc-client";
import {take} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" }
})
export class ProfileComponent implements OnInit {
  user$;
  saved: boolean = false;
  savedText;
  fields;
  formGlobals: any = {};
  formSettings: any = {};
  constructor(
    private oidcSecurityService: OidcSecurityService,
    public httpClient: HttpClient,
    public router: Router
  ) {
  }

  async ngOnInit() {
    this.user$ = this.oidcSecurityService.getUserData();

    this.user$.pipe(
      take(1)
    ).subscribe(
      user => {
        this.fields = [
          {
            type: "control",
            control: "input",
            name: "fullName",
            label: "FullName",
            value: user.name,
            validator: []
          },
          {
            type: "control",
            control: "input",
            name: "email",
            label: "Email",
            value: user.email,
            validator: ["email"]
          }
        ];

        if (user.role === "seller") {
          let sellerEmailField = {
            type: "control",
            control: "input",
            name: "store_url",
            label: "Store URL",
            value: user.sellerInfo.store_url ? user.sellerInfo.store_url : "",
            validator: []
          };

          this.fields.push(sellerEmailField);
        }

        this.formSettings = {
          submitText: i18next.t("Save")
        };
      }
    );
  }

  onSubmitForm(form) {
    // let url = "users/edit-user";
    // this.saved = true;
    // this.savedText = i18next.t("SavingLoading");
    // this.httpService.post(
    //   url,
    //   form.value,
    //   response => {
    //     this.authService.setUserData(JSON.stringify(response.body));

    //     setTimeout(() => {
    //       this.savedText = i18next.t("Saved");
    //     }, 1000);

    //     setTimeout(() => {
    //       this.saved = false;
    //     }, 2500);
    //   },
    //   error => {
    //     this.formGlobals.globalError = i18next.t("EmailAlreadyInUse");
    //   }
    // );
  }
}
