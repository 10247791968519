import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class DataSubscription {

  source: BehaviorSubject<any>;
  observable;

  constructor(value) {
    this.source = new BehaviorSubject<any>(value);
    this.observable = this.source.asObservable();
  }
}
