import { Component, OnInit } from '@angular/core';
import i18next from 'i18next';
import {timeInterval} from 'rxjs/operators';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  languages;
  currentLang;

  constructor() {
    this.languages = [
      { key: 'de', text: 'DE'},
      { key: 'en', text: 'EN'}
    ];
    this.currentLang = i18next.language;
    if (!this.currentLang) {
      this.currentLang = 'de';
      i18next.changeLanguage(this.currentLang).then(response => {
        console.log('Language changed!');
      });
    }
  }

  ngOnInit() {
  }

  switchLanguage(event) {
    i18next.changeLanguage(event).then(repsonse => {
      location.reload();
    });
  }
}