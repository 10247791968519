import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {fadeInAnimation} from '../../../animations/fade-animation';
import * as _ from 'lodash';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import i18next from 'i18next';
import {MatDialog} from '@angular/material';
import {PdfPreviewComponent} from '../pdf-preview/pdf-preview.component';
import {saveAs} from 'file-saver';
import {DialogComponent} from '../../modals/dialog/dialog.component';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {HttpClient} from "@angular/common/http";
import {catchError, filter, take} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {ITableColumn, ITableData, ITablePaginator, ITableRowData} from "invoicecloud-modules";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  animations: [
    fadeInAnimation,
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', visibility: 'hidden'})),
      state('expanded', style({
        height: 'auto',
        visibility: 'visible',
        padding: '20px 5px 20px 5px',
        margin: '10px 20px',
        border: '5px solid #efefef'
      })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  host: {'[@fadeInAnimation]': ''}
})

export class OrdersComponent implements OnInit {

  displayColumns: ITableColumn[] = [
    {slug: 'index', label: i18next.t('Number')},
    {slug: 'order_date', label: i18next.t('Date')},
    {slug: 'amazon_id', label: i18next.t('OrderNumber')},
    {slug: 'seller', label: i18next.t('Seller')},
    {slug: 'quantity', label: i18next.t('Qty')},
    {slug: 'product', label: i18next.t('Product')},
    {slug: 'price', label: i18next.t('Price')},
    {slug: 'status', label: ''}
  ];
  paginator: ITablePaginator = {
    pageSizeOptions: [10, 20, 50],
    pageSize: 10,
    length: 0,
    pageIndex: 0
  };
  buttonColumns: string[] = ['status'];
  cellStyle = {
    amazon_id: {
      paddingTop: '10px',
      width: '15%'
    },
    order_date: {
      width: '8%'
    },
    seller: {
      width: '10%'
    },
    quantity: {
      fontSize: '12px',
    },
    product: {
      fontSize: '12px',
    },
    price: {
      fontSize: '12px',
      width: '5%'
    },
    status: {
      width: '5%'
    }
  };
  tableData: ITableData;

  filterOptionsToDisplay: object[];
  selectedFilterType: string = null;
  search: string = null;
  i18next = i18next;
  _ = _;
  ordersToDelete = [];
  allOrdersSelected = false;
  isUserAdmin = null;

  constructor(public httpClient: HttpClient,
              public router: Router,
              public oidcSecurityService: OidcSecurityService,
              private activeRoute: ActivatedRoute,
              public dialog: MatDialog) {
    this.oidcSecurityService.getUserData()
      .pipe(take(1))
      .subscribe(user => {
        let guideVisitedKey = environment.oidc_configs.openIdConfiguration.client_id + '_guide_visited';
        if (user && (!user.userData || !user.userData.hasOwnProperty(guideVisitedKey))) {
          return this.router.navigate(['/guide']);
        }
      });
  }

  ngOnInit() {
    this.search = this.activeRoute.snapshot.queryParamMap.get('search');

    this.activeRoute.queryParamMap.subscribe(queryParams => {
      this.search = queryParams.get('search');
      this.fetchOrders(this.paginator, this.search);
    });

    this.fetchOrders(this.paginator, this.search);
  }

  fetchOrders(paginator, search) {
    this.ordersToDelete = [];
    this.tableData = null;
    let url = environment.api_host_url + environment.api_rest_prefix + 'orders?pageIndex=' + (paginator.pageIndex + 1) + '&pageSize=' + paginator.pageSize;

    if (search) {
      url += '&search=' + search;
    }

    if (this.selectedFilterType) {
      url += '&filterType=' + this.selectedFilterType;
    }

    this.httpClient.get(url)
      .pipe(
        take(1),
        catchError(e => {
          return e;
        })
      ).subscribe((response: Params) => {
      this.paginator.length = response.total;
      this.paginator.pageSize = paginator.pageSize;
      this.paginator.pageIndex = paginator.pageIndex;

      let {data} = response;
      let rowsData: ITableRowData[] = [];
      let orderNumber = response.total - (paginator.pageSize * paginator.pageIndex);

      _.forEach(data, (order) => {
        let {invoices, meta} = order;
        let filteredData: ITableRowData = {
          cellStyle: this.cellStyle,
          index: orderNumber--,
          order_date: meta ? meta.order_date : '',
          amazon_id: meta ? `<a href=${order.amazonOrderUrl} target="_blank" class="external-link"> ${meta.amaz_order_id}</a>` : '',
          seller: [],
          product: [],
          quantity: [],
          price: [],
          status: [],
          invoices
        };

        _.forEach(invoices, (invoice) => {
          let sellerName = (
            invoice.seller.meta.hasOwnProperty('amazon_detailedSellerInfo') && invoice.seller.meta.amazon_detailedSellerInfo.hasOwnProperty('BusinessName')
          ) ?
            invoice.seller.meta.amazon_detailedSellerInfo.BusinessName :
            invoice.seller.meta.amazon_id;
          let sellerPageLink = `<a href=${invoice.seller.amazonSellerUrl} target="_blank" class="external-link">${sellerName}</a>`;
          filteredData.seller.push(sellerPageLink);

          filteredData.status.push(this.initInvoiceStatusHTML(+invoice.status.id));
          filteredData.product.push(this.mapAndJoinSubRows(invoice.items, 'product', '<br>'));
          filteredData.quantity.push(this.mapAndJoinSubRows(invoice.items, 'quantity', '<br>'));
          filteredData.price.push(this.mapAndJoinSubRows(invoice.items, 'price',  '<br>', 'toFixed', [2]));
        });

        rowsData.push(filteredData);
      });

      this.tableData = {
        paginator: this.paginator,
        displayColumns: this.displayColumns,
        buttonColumns: this.buttonColumns,
        rowsData
      };
    });
  }

  mapAndJoinSubRows(arr: any[],
              prop: string,
              needle: string,
              func: string = null,
              funcInput: any[] = null): string {
    if (func) {
      return _.map(
        _.map(arr, 'item'),
        (subItem) => {
          return subItem[prop][func](funcInput);
        }
      ).join(needle);
    }

    return _.map(
      _.map(arr, 'item'),
      prop
    ).join(needle);
  }

  initInvoiceStatusHTML(statusId) {
    switch (statusId) {
      case 1:
        return '<img src="assets/images/icons/pdf-file-green.svg" alt="Status">';
      case 2:
        return '<i class="row-invoice__is__status__icon__green material-icons cursor-pointer">get_app</i>';
      case 3:
        return '<div class="row-invoice__is__status__yellow"></div>';
      case 4:
        return '<div class="row-invoice__is__status__red"></div>';
      case 5:
        return '<i class="row-invoice__is__status__icon__grey material-icons cursor-pointer">get_app</i>';
      default:
        return '';
    }
  }

  tableButtonClickHandler(event) {
    if (event.target.innerText === 'get_app') {
      let {element} = event;
      let index = 0;
      if (element.subRowIndex) {
        index = element.subRowIndex;
      }

      this.pdfPreview(element.invoices[index]);
    }
  }

  pdfPreview(invoice) {
    const dialogRef = this.dialog.open(PdfPreviewComponent, {
      width: '800px',
      data: {
        invoice
      }
    });

    if (invoice.status.id !== 5) {
      let url = environment.api_host_url + environment.api_rest_prefix + "invoices/" + invoice.id + "/change-status";
      let status = 5;
      this.httpClient.post(
        url,
        {status})
        .pipe(
          catchError(e => {
            console.log(e);
            return e;
          })
        )
        .subscribe(response => {
          return true;
        });
    }

    dialogRef.afterClosed().subscribe((action) => {
      this.fetchOrders(this.paginator, this.search);
    });
  }

  downloadDataAsCSV() {

    let columns = [
      i18next.t('Number'),
      i18next.t('Date'),
      i18next.t('OrderNumber'),
      i18next.t('Seller'),
      i18next.t('Qty'),
      i18next.t('Product'),
      i18next.t('Price')
    ];

    let rowIndex = this.paginator.length - (this.paginator.pageSize * this.paginator.pageIndex);

    let csvStr = columns.map(row => {
      return row;
    }).join(',');
    csvStr += '\r\n';
    csvStr += this.tableData.rowsData.map((order, index) => {

      let csvRow = [];
      csvRow.push(rowIndex--);
      csvRow.push(order.created_at);
      csvRow.push(order.amazon_id);
      order.invoices.map(invoice => {
        csvRow.push(invoice.seller.meta.amazon_id);
        invoice.items.map(item => {
          csvRow.push(item.item.quantity);
          csvRow.push(item.item.product);
          csvRow.push(item.item.price);
        });
      });

      return csvRow.join(',');

    }).join('\r\n');

    let blob = new Blob([csvStr], {type: 'text/csv'});
    saveAs(blob, 'list.csv');
  }

  pushToDelete(elementId) {
    if (this.ordersToDelete.includes(elementId)) {
      let elementIndex = this.ordersToDelete.indexOf(elementId);
      this.ordersToDelete.splice(elementIndex, 1);
    } else {
      this.ordersToDelete.push(elementId);
    }
  }

  selectAll() {
    this.allOrdersSelected = this.allOrdersSelected ? false : true;

    for (let order of this.tableData.rowsData) {
      if (this.allOrdersSelected) {
        this.ordersToDelete.push(order.id);
        order.selected = true;
      } else {
        order.selected = false;
      }
    }

    if (!this.allOrdersSelected) {
      this.ordersToDelete = [];
    }
  }

  submitDeleteOrders() {
    let self = this;

    if (this.ordersToDelete.length > 0) {
      const deleteDialog = this.dialog.open(DialogComponent, {
        width: '400px',
        data: {
          title: i18next.t('DeleteOrders'),
          text: i18next.t('DeleteOrdersConfirmation')
        }
      });

      deleteDialog.afterClosed().subscribe(result => {
        if (result) {
          this.httpClient.post(
            environment.api_host_url + environment.api_rest_prefix + 'orders-delete/', {orderIds: self.ordersToDelete})
            .pipe(
              catchError(e => {
                return e;
              })
            )
            .subscribe((response: Params) => {
              self.fetchOrders(self.paginator, self.search);
            });
        }
      });
    }
  }

  filterOrders(value) {
    if (!value && !this.selectedFilterType) {
      return false;
    }

    this.selectedFilterType = value;
    this.ngOnInit();
  }
}
