import {Injectable} from '@angular/core';
import {DataSubscription} from '../models/DataSubscription';

@Injectable()
export class DataService {
  subscriptions = {};

  initialize(key) {
    if (!this.subscriptions.hasOwnProperty(key)) {
      this.subscriptions[key] = new DataSubscription('');
    }
  }

  set(key, value) {
    this.subscriptions[key].source.next(value);
  }

}
