import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-satisfaction',
  templateUrl: './customer-satisfaction.component.html',
  styleUrls: ['./customer-satisfaction.component.scss']
})
export class CustomerSatisfactionComponent implements OnInit {
  surveyResults = [
    {
      question: "How would you rate seller's services?",
      checked: 0
    },
    {
      question: "How satisfied or dissatisfied are you with the performance of customer service representatives?",
      checked: 0
    },
    {
      question: "How would you rate your experience with the product/s?",
      checked: 0
    },
    {
      question: "Value for money of the product.",
      checked: 0
    },
    {
      question: "How would you rate the quality of the product/s?",
      checked: 0
    }
  ];
  optionalFieldValue: string = null;

  constructor() { }

  ngOnInit() {
  }

  submitSurvey() {
    console.log(this.surveyResults);
    console.log(this.optionalFieldValue);
  }

}
