import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";
@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"]
})
export class UserComponent implements OnInit {
  user$ = null;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    public router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.user$ = this.oidcSecurityService.getUserData();
  }
}
