import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import { CustomValidatons } from 'src/app/services/validators/CustomValidatons';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit, OnChanges {

  @Output() submitFormEvent = new EventEmitter();
  @Output() controlEventEmitter = new EventEmitter<any>();
  @Input() fields: any[] = [];
  @Input() settings: any = {};
  @Input() globals: any = {};
  @Input() reset: boolean = false;
  form;
  formSubmitted: boolean = false;
  strongPasswordPattern: string = '^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W).*$';
  _ = _;

  constructor() {
    this.form = new FormGroup({});
  }

  ngOnInit() {
    this.buildForm(this.form, null);
  }

  ngOnChanges() {
    if (this.reset) {
      this.form.reset();
    }
  }

  buildForm(controls, group) {
    let loop = this.fields;
    if (group) {
      loop = group;
    }
    for (const field of loop) {
      if (field.hasOwnProperty('items')) {
        controls.addControl(field.name, new FormGroup({}));
        this.buildForm(controls.controls[field.name], field.items);
      } else {
        controls.addControl(field.name, this.buildFormControl(field));
      }
    }
  }

  buildFormControl(field) {
    let validations = [];
    let self = this;

    for (const validation of field.validator) {
      if (validation === 'required') {
        validations.push(Validators.required);
      }
      if (validation === 'email') {
        validations.push(Validators.email);
      }
      if (_.includes(validation, 'confirm:')) {
        let splitValidation = _.split(validation, ':');
        field.compare_with = splitValidation[1];
      }

      if (validation === 'password') {
        // validations.push(Validators.pattern('^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W).*$'));
        // 1. check whether the entered password has a number
        validations.push(CustomValidatons.patternValidator(/\d/, { hasNumber: true }));
        // 2. check whether the entered password has upper case letter
        validations.push(CustomValidatons.patternValidator(/[A-Z]/, { hasCapitalCase: true }));
        // 3. check whether the entered password has a lower-case letter
        validations.push(CustomValidatons.patternValidator(/[a-z]/, { hasSmallCase: true }));
        // 4. check whether the entered password has a special character
        validations.push(CustomValidatons.patternValidator(/[!@#=_()\$%\^&\*]/, { hasSpecialCharacters: true }));
        // 5. Has a minimum length of 8 characters
        validations.push(Validators.minLength(8));
      }
    }
    return new FormControl(field.value, validations);
  }

  controlEventListener(event) {
    this.controlEventEmitter.emit(event);
  }

  onSubmitForm() {
    this.formSubmitted = true;
    if (_.has(this.globals, 'globalError')) {
      this.globals.globalError = null;
    }
    if (_.has(this.globals, 'globalSuccess')) {
      this.globals.globalSuccess = null;
    }
    this.submitFormEvent.emit(this.form);
  }

}
