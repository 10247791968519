import {Component, HostListener, OnInit} from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-guest-header',
  templateUrl: './guest-header.component.html',
  styleUrls: ['./guest-header.component.scss']
})
export class GuestHeaderComponent implements OnInit {

  constructor(
    private oidcSecurityService: OidcSecurityService
  ) { }

  ngOnInit() {
  }
}
