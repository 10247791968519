import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {filter, take} from "rxjs/operators";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService
  ) {
  }

  async ngOnInit() {
    this.oidcSecurityService.getIsAuthorized().pipe(
      filter(isLogged => isLogged === true),
      take(1)
    ).subscribe(isLogged => {
      if (isLogged === true) {
        return this.router.navigate(['/orders']);
      }
    });
  }
}
