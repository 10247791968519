import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MainComponent} from '../../components/amazonomat/main/main.component';
import {AppAmazonomatRoutingModule} from './app-amazonomat-routing.module';
import {NotFoundComponent} from '../../components/amazonomat/not-found/not-found.component';
import {GuestHeaderComponent} from '../../components/amazonomat/headers/guest-header/guest-header.component';
import {
  MatDialogModule,
  MatDividerModule,
  MatIconModule,
  MatMenuModule,
  MatPaginatorModule, MatProgressSpinnerModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatCheckboxModule,
  MatProgressBarModule,
  MatInputModule,
  MatTooltipModule,
  MatSelectModule,
  MatButtonModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HomeComponent} from '../../components/amazonomat/home/home.component';
import {I18NextModule} from 'angular-i18next';
import {OrdersComponent} from '../../components/amazonomat/orders/orders.component';
import {PartnersComponent} from '../../components/amazonomat/partners/partners.component';
import {InvoicesComponent} from '../../components/amazonomat/invoices/invoices.component';
import {ProfileComponent} from '../../components/amazonomat/profile/profile.component';
import {FormBuilderModule} from '../form-builder/form-builder.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DataService} from '../../services/DataService';
import {PdfPreviewComponent} from '../../components/amazonomat/pdf-preview/pdf-preview.component';
import {DialogComponent} from '../../components/modals/dialog/dialog.component';
import {ConfirmComponent} from '../../components/modals/confirm/confirm.component';
import {NgxPrintModule} from 'ngx-print';
import {AvatarModule} from 'ngx-avatar';
import { UserSettingsComponent } from '../../components/amazonomat/user-settings/user-settings.component';
import { UserComponent } from '../../components/amazonomat/user/user.component';
import {LoaderComponent} from '../../components/globals/loader/loader.component';
import { MainHeaderComponent } from '../../components/amazonomat/headers/main-header/main-header.component';
import { UserHeaderComponent } from '../../components/amazonomat/headers/user-header/user-header.component';
import { LanguageSwitcherComponent } from '../../components/amazonomat/headers/header-elements/language-switcher/language-switcher.component';
import { CustomerSatisfactionComponent } from '../../components/amazonomat/surveys/customer-satisfaction/customer-satisfaction.component';
import { SafePipe } from 'src/app/pipes/safe.pipe';

import {BearerHttInterceptor} from "../../services/auth/BearerHttInterceptor";
import {environment} from "../../../environments/environment";
import {OidcAuthService} from "../../services/auth/OidcAuthService";
import { AuthModule, OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { AmazonProductInfoComponent } from 'src/app/components/amazonomat/amazon-product-info/amazon-product-info.component';
import {NewUserGuideComponent} from "../../components/amazonomat/new-user-guide/new-user-guide.component";
import {SlideshowModule} from "ng-simple-slideshow";
import {NgxStripeModule} from "ngx-stripe";
import {TestStripeComponent} from "../../components/amazonomat/test-stripe/test-stripe.component";
import {PricingComponent} from "../../components/amazonomat/pricing/pricing.component";
import {CheckoutComponent} from "../../components/amazonomat/checkout/checkout.component";
import {InvoiceCloudModule} from "invoicecloud-modules";

@NgModule({
  declarations: [
    MainComponent,
    NotFoundComponent,
    GuestHeaderComponent,
    MainHeaderComponent,
    UserHeaderComponent,
    LanguageSwitcherComponent,
    HomeComponent,
    OrdersComponent,
    PartnersComponent,
    InvoicesComponent,
    ProfileComponent,
    PdfPreviewComponent,
    UserSettingsComponent,
    UserComponent,
    LoaderComponent,
    CustomerSatisfactionComponent,
    SafePipe,
    AmazonProductInfoComponent,
    NewUserGuideComponent,
    TestStripeComponent,
    PricingComponent,
    CheckoutComponent
  ],
  imports: [
    NgxStripeModule.forRoot(),
    AppAmazonomatRoutingModule,
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    I18NextModule.forRoot(),
    FormBuilderModule,
    HttpClientModule,
    NgxPrintModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    AvatarModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    AuthModule.forRoot(),
    InvoiceCloudModule.forRoot(environment),
    SlideshowModule
  ],
  entryComponents: [DialogComponent, ConfirmComponent, PdfPreviewComponent],
  providers: [
    DataService,
    OidcSecurityService,
    OidcConfigService,
    OidcAuthService,
    {  provide: HTTP_INTERCEPTORS,
      useClass: BearerHttInterceptor,
      multi: true
    },
  ]
})
export class AppAmazonomatModule {
  constructor(public oidcSecurityService: OidcSecurityService) {
    oidcSecurityService.setupModule(environment.oidc_configs.openIdConfiguration, environment.oidc_configs.authWellKnownEndpoints);
  }
}
