import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {AppAmazonomatRoutingModule} from './modules/amazonomat/app-amazonomat-routing.module';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {defaultInterpolationFormat, I18NEXT_SERVICE, I18NextLoadResult, I18NextModule, ITranslationService} from 'angular-i18next';
import * as i18nextXHRBackend from 'i18next-xhr-backend';
import * as i18nextLanguageDetector from 'i18next-browser-languagedetector';
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';

import {environment} from '../environments/environment';

import {AuthCheckService} from './services/routing/AuthCheckService';

import {AppAmazonomatModule} from './modules/amazonomat/app-amazonomat.module';

import {DialogComponent} from './components/modals/dialog/dialog.component';
import {ConfirmComponent} from './components/modals/confirm/confirm.component';
import {LoggedCheckService} from './services/routing/LoggedCheckService';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {UserDataService} from "./services/auth/UserDataService";

const i18nextOptions = {
  whitelist: ['en', 'de'],
  fallbackLng: 'en',
  debug: true, // set debug?
  returnEmptyString: false,
  ns: [
    'translation'
  ],
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat)
  },
  backend: {
    loadPath(langs, ns) {
      return 'assets/locales/{{lng}}/{{ns}}.json';
    }
  },
  detection: {
    order: ['localStorage', 'cookie'],
    lookupLocalStorage: 'i18nextLng',
    lookupCookie: 'lang',
    caches: ['localStorage', 'cookie'],
  }
};

export function appInit(i18next: ITranslationService) {
  return () => {
    const promise: Promise<I18NextLoadResult> = i18next
      .use(i18nextXHRBackend)
      .use(i18nextLanguageDetector)
      .init(i18nextOptions);
    return promise;
  };
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  },
];

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    DialogComponent,
    ConfirmComponent,
  ],
  entryComponents: [DialogComponent, ConfirmComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    I18NextModule.forRoot(),
    (environment.appName === 'amazonomat') ? AppAmazonomatModule : [],
    (environment.appName === 'amazonomat') ? AppAmazonomatRoutingModule : [],
  ],
  providers: [
    AuthCheckService,
    LoggedCheckService,
    UserDataService,
    I18N_PROVIDERS,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
