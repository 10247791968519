import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '../../components/amazonomat/main/main.component';
import {NotFoundComponent} from '../../components/amazonomat/not-found/not-found.component';
import {HomeComponent} from '../../components/amazonomat/home/home.component';
import {ProfileComponent} from '../../components/amazonomat/profile/profile.component';
import {AuthCheckService} from '../../services/routing/AuthCheckService';
import { UserSettingsComponent } from 'src/app/components/amazonomat/user-settings/user-settings.component';
import { UserComponent } from 'src/app/components/amazonomat/user/user.component';
import {OrdersComponent} from '../../components/amazonomat/orders/orders.component';
import { CustomerSatisfactionComponent } from 'src/app/components/amazonomat/surveys/customer-satisfaction/customer-satisfaction.component';
import { AmazonProductInfoComponent } from '../../components/amazonomat/amazon-product-info/amazon-product-info.component';
import {NewUserGuideComponent} from "../../components/amazonomat/new-user-guide/new-user-guide.component";
import {TestStripeComponent} from "../../components/amazonomat/test-stripe/test-stripe.component";
import {PricingComponent} from "../../components/amazonomat/pricing/pricing.component";
import {CheckoutComponent} from "../../components/amazonomat/checkout/checkout.component";

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'test-stripe',
        component: TestStripeComponent
      },
      {
        path: 'home',
        redirectTo: 'orders',
        pathMatch: 'full'
      },
      {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [AuthCheckService]
      },
      {
        path: 'invoice-preview/:id',
        component: OrdersComponent,
        canActivate: [AuthCheckService]
      },
      {
        path: 'user',
        component: UserComponent,
        canActivate: [AuthCheckService],
        children: [
          {
            path: 'profile',
            component: ProfileComponent,
            canActivate: [AuthCheckService]
          },
          {
            path: 'settings',
            component: UserSettingsComponent,
            canActivate: [AuthCheckService]
          },
        ]
      },
      {
        path: 'customer-satisfaction-survey',
        component: CustomerSatisfactionComponent,
        canActivate: [AuthCheckService]
      },
      {
        path: 'amazon-product-info',
        component: AmazonProductInfoComponent,
        canActivate: [AuthCheckService]
      },
      {
        path: 'guide',
        component: NewUserGuideComponent,
        canActivate: [AuthCheckService]
      },
      {
        path: 'pricing',
        component: PricingComponent,
        canActivate: [AuthCheckService]
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [AuthCheckService]
      },
      {
        path: 'logout',
        component: HomeComponent,
        canActivate: [AuthCheckService]
      },
      {
        path: 'sign-in',
        component: HomeComponent,
        canActivate: [AuthCheckService]
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppAmazonomatRoutingModule {
}
