import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, take, tap} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {OidcSecurityService} from "angular-auth-oidc-client";

@Injectable()
export class UserDataService {
  constructor(private http: HttpClient,
              private oidcSecurityService: OidcSecurityService) {}

  setUserData(key, value) {
    const payload = {
      key,
      value
    };

    const url = environment.firebaseBaseApi + '/user-data';
    const self = this;
    return this.http.post(url,  payload).pipe(
      tap(() => self.oidcSecurityService.getUserinfo()
        .pipe(take(1))
        .subscribe()),
      catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );
  }
}
