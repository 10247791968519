import {Component, OnInit} from '@angular/core';
import {fadeInAnimation} from '../../../animations';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {IHeaderData} from "invoicecloud-modules";
import {environment} from "../../../../environments/environment";
import {IFooterData} from "invoicecloud-modules";
import {Observable} from "rxjs";
import i18n from 'i18next';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [fadeInAnimation],
  host: {'[@fadeInAnimation]': ''}
})
export class MainComponent implements OnInit {
  public headerData: IHeaderData = {
    client_id: environment.appName,
    backgroundColor: '#232f3e',
    defaultColor: '#ff9900',
    logoUrl: 'assets/images/amazonomat-logo.png',
    fontColor: '#fff',
    buttonFontColor: '#fff',
    routes: [
      {name: i18n.t('AmazonProductInfo'), src: 'amazon-product-info'},
      {name: i18n.t('Pricing'), src: 'pricing'}
    ]
  };

  public footerData: IFooterData = {
    client_id: environment.appName,
    backgroundColor: '#efefef',
    defaultColor: '#ff9900'
  };

  user$: Observable<any> = null;

  constructor(public oidcSecurityService: OidcSecurityService) {
    if (this.oidcSecurityService.moduleSetup) {
      this.doCallbackLogicIfRequired();
    } else {
      this.oidcSecurityService.onModuleSetup.subscribe(() => {
        this.doCallbackLogicIfRequired();
      });
    }
  }

  ngOnInit() {
    this.user$ = this.oidcSecurityService.getUserData();
  }

  private doCallbackLogicIfRequired() {
    // Will do a callback, if the url has a code and state parameter.
    this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
  }

  changeLang(event) {
    i18n.changeLanguage(event.lang).then(() => {
      window.location.reload();
    });
  }
}
